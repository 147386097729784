import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Check from '@material-ui/icons/Check'
import SettingsIcon from '@material-ui/icons/Settings'
import GroupAddIcon from '@material-ui/icons/GroupAdd'
import VideoLabelIcon from '@material-ui/icons/VideoLabel'
import StepConnector from '@material-ui/core/StepConnector'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import Container from '@material-ui/core/Container'
import Hidden from '@material-ui/core/Hidden'

import { Link as Link2 } from 'react-router-dom'

import Trolley from './trolley'
import OrderSummary from './ordersummary'
import PaymentOption from './paymentoption'
import OrderConfirm from './orderconfirm'

const scrollToTop = () => {
  window.scrollTo(0, 0)
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  stepperRoot: {
    paddingBottom: 0,
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  paperroot: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
  },
  toolbar: {
    paddingTop: theme.spacing(0),
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
    paddingBottom: theme.spacing(0),
  },
}))

function getSteps() {
  return [
    'Review your trolley',
    'Order Summary',
    'Payment options',
    'Order confirmation',
  ]
}

export default function CustomizedSteppers() {
  const classes = useStyles()
  const [activeStep, setActiveStep] = React.useState(0)
  const [payment, setPayment] = React.useState('touchngo')
  const [orderNo, setOrderNo] = React.useState()
  const steps = getSteps()

  const handleNext = () => {
    scrollToTop()
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleReset = () => {
    setActiveStep(0)
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <Trolley handleNext={handleNext} />
      case 1:
        return <OrderSummary handleNext={handleNext} />
      case 2:
        return (
          <PaymentOption
            setPayment={setPayment}
            payment={payment}
            handleNext={handleNext}
            setOrderNo={setOrderNo}
          />
        )
      default:
        return (
          <OrderConfirm
            payment={payment}
            orderNo={orderNo}
            handleNext={handleNext}
          />
        )
    }
  }

  return (
    <React.Fragment>
      <div
        style={{
          backgroundColor: 'white',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div style={{ maxWidth: 1000, width: '100%' }}>
          <Stepper
            classes={{ root: classes.stepperRoot }}
            alternativeLabel
            activeStep={activeStep}
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>
                  <Hidden only={['sm', 'xs']}>
                    <h3>{label}</h3>
                  </Hidden>
                </StepLabel>
              </Step>
            ))}
          </Stepper>

          <Hidden only={['md', 'lg', 'xl']}>
            <div style={{ textAlign: 'center', paddingBottom: 20 }}>
              {steps[activeStep]}
            </div>
          </Hidden>
        </div>
      </div>
      <Divider />
      <Container style={{ paddingLeft: 8, paddingRight: 8 }} maxWidth="md">
        <div className={classes.toolbar}>
          <div>
            {activeStep === steps.length ? (
              <div>
                <Typography className={classes.instructions}>
                  All steps completed - you&apos;re finished
                </Typography>
                <Button onClick={handleReset} className={classes.button}>
                  Reset
                </Button>
              </div>
            ) : (
              <div style={{ paddingTop: 8, paddingBottom: 8 }}>
                {getStepContent(activeStep)}
                {/*<div>
                   {activeStep > 0 && (
                    <Button
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      className={classes.button}
                    >
                      Back
                    </Button>
                  )}
                  <Button
                    variant="contained"
disableElevation
                    color="primary"
                    onClick={handleNext}
                    className={classes.button}
                  >
                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                  </Button>
                </div> */}
              </div>
            )}
          </div>
        </div>
      </Container>
    </React.Fragment>
  )
}
