import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Button } from '@material-ui/core/'

import Hidden from '@material-ui/core/Hidden'
import Paper from '@material-ui/core/Paper'
import { GET_CARTS } from '../graphql/cart'
import { CART_ORDER } from '../graphql/order'
import { GET_SHOPCONFIG } from '../graphql/config'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { useEffect } from 'react'
import { GET_POSTCODEPRICE } from '../graphql/postcode'

import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'

const restApi = 'http://frapp.cloud:3501'

const scrollToTop = () => {
  window.scrollTo(0, 0)
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(0),
  },
  paperroot: {
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
    border: (props) => `1px solid ${props.COLORPRIMARY}`,
    borderBottom: (props) => `5px solid ${props.COLORPRIMARY}`,
  },
  summaryRoot: {
    padding: theme.spacing(3),
    border: '1px solid #b4b4b4',
  },
  dividerRoot: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  button: {
    marginTop: theme.spacing(1),
  },
}))

export default function PaymentOption(props) {
  const [totalAmount, setTotalAmount] = useState(0)
  const [finalAmount, setFinalAmount] = useState(0)

  const {
    loading: loadingConfig,
    data: { shopconfig } = { shopconfig: {} },
    refetch: refetchConfig,
  } = useQuery(GET_SHOPCONFIG)

  const classes = useStyles(shopconfig)

  const {
    data: { postcodeprice } = { postcodeprice: { id: null } },
  } = useQuery(GET_POSTCODEPRICE)

  const {
    loading: loadingCarts,
    data: { carts } = { carts: [] },
    refetch,
  } = useQuery(GET_CARTS)

  const updateCache = (cache, { data }) => {
    props.setOrderNo(data.cartorder)
  }

  const [cartOrder] = useMutation(CART_ORDER, { update: updateCache })

  useEffect(() => {
    if (carts.length == 0) return

    const totalAmount = carts.reduce((a, b) => a + b.InvoiceAmount, 0)
    setTotalAmount(totalAmount)
  }, [carts])

  useEffect(() => {
    setFinalAmount(postcodeprice.id && postcodeprice.Price + totalAmount)
  }, [postcodeprice, totalAmount])

  return (
    <React.Fragment>
      <Grid container spacing={1}>
        <Hidden only={['sm', 'xs']}>
          <Grid item xs={12} md={2}></Grid>
        </Hidden>
        <Grid item xs={12} md={8}>
          <Paper classes={{ root: classes.summaryRoot }} variant="outlined">
            <h3 style={{ marginTop: 0, fontSize: 17 }}>
              Order Total: RM {parseFloat(finalAmount).toFixed(2)}
            </h3>
            <br />
            <FormControl component="fieldset">
              <FormLabel component="legend">
                Please select the payment method below:
              </FormLabel>
              <RadioGroup
                aria-label="gender"
                name="gender1"
                value={props.payment}
                onChange={(e) => {
                  props.setPayment(e.target.value)
                }}
              >
                <FormControlLabel
                  value="touchngo"
                  control={<Radio />}
                  label="Touch N Go eWallet"
                />
                <FormControlLabel
                  value="banktransfer"
                  control={<Radio />}
                  label="Bank Transfer (INSTANT TRANSFER ONLY)"
                />
              </RadioGroup>
            </FormControl>
          </Paper>
          <Button
            fullWidth
            variant="contained"
            disableElevation
            color="primary"
            onClick={() => {
              cartOrder({
                variables: {
                  PaymentMethod: props.payment,
                },
              })
              props.handleNext()
            }}
            className={classes.button}
          >
            Confirm payment
          </Button>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}
