import React, { useState, useEffect } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { Link as Link2, useHistory } from 'react-router-dom'
import List from '@material-ui/core/List'
import Paper from '@material-ui/core/Paper'
import ListItem from '@material-ui/core/ListItem'
import Divider from '@material-ui/core/Divider'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputBase from '@material-ui/core/InputBase'
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined'
import IndeterminateCheckBoxOutlinedIcon from '@material-ui/icons/IndeterminateCheckBoxOutlined'
import CartItem from './cartItem'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import { GET_SHOPCONFIG } from '../graphql/config'
import { useMutation, useQuery } from '@apollo/react-hooks'

import ImageGallery from 'react-image-gallery'

import 'react-image-gallery/styles/css/image-gallery.css'
import Flickity from 'react-flickity-component'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import HomeIcon from '@material-ui/icons/Home'
import WhatshotIcon from '@material-ui/icons/Whatshot'
import GrainIcon from '@material-ui/icons/Grain'
import Cookies from 'js-cookie'
import { PRODUCT_IMAGES, GET_PRODUCT } from '../graphql/product'
import {
  GET_CARTS,
  INSERT_CART,
  UPDATE_CART,
  REMOVE_CART,
} from '../graphql/cart'
import { GET_CATEGORIES } from '../graphql/category'
import Cart from './cart'
import Menu from './menu'
import MenuCategory from './menuCategory'
import Loading from '../common/loading'

const scrollToTop = () => {
  window.scrollTo(0, 0)
}

const restApi = 'http://frapp.cloud:3501'
const useStyles = makeStyles((theme) => ({
  paperroot: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    padding: theme.spacing(2),
    border: '1px solid #b4b4b4',
    marginBottom: theme.spacing(1),
  },
  link: {
    display: 'flex',
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
  productDesc: {
    fontWeight: 'normal',
  },
}))

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 0,
    position: 'relative',
    backgroundColor: theme.palette.grey[200],
    border: 1,
    borderColor: theme.palette.primary.main,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
  },
}))(InputBase)

export default function ShopDetails(props) {
  const [images, setImages] = useState([])
  const [totalAmount, setTotalAmount] = useState(0)

  const {
    loading: loadingConfig,
    data: { shopconfig } = { shopconfig: {} },
    refetch: refetchConfig,
  } = useQuery(GET_SHOPCONFIG)

  const {
    loading: loadingProduct,
    data: { product } = { product: {} },
    refetch: refetchProduct,
  } = useQuery(GET_PRODUCT, { variables: { id: props.match.params.id } })

  const {
    loading: loadingImages,
    data: { productimages } = { productimages: [] },
    refetch: refetchImages,
  } = useQuery(PRODUCT_IMAGES, { variables: { id: props.match.params.id } })

  const { data: { categories } = { categories: [] } } = useQuery(GET_CATEGORIES)

  const {
    loading: loadingCarts,
    data: { carts } = { carts: [] },
    refetch: refetchCart,
  } = useQuery(GET_CARTS, { skip: !Cookies.get('signedin') })

  const addCache = (cache, { data }) => {
    const old = cache.readQuery({
      query: GET_CARTS,
    })
    const latest = data.insertcart
    cache.writeQuery({
      query: GET_CARTS,
      data: { carts: [...old.carts, latest] },
    })
  }

  const [insertCart] = useMutation(INSERT_CART, {
    update: addCache,
  })

  const updateCache = (cache, { data }) => {
    const old = cache.readQuery({
      query: GET_CARTS,
    })
    const latest = data.updatecart
    const foundIndex = old.carts.findIndex((item) => item.id === latest.id)
    old.carts.splice(foundIndex, 1, latest)
    cache.writeQuery({
      query: GET_CARTS,
      data: { carts: [...old.carts] },
    })
  }

  const [updateCart] = useMutation(UPDATE_CART, { update: updateCache })

  const deleteCache = (cache, { data }) => {
    const old = cache.readQuery({
      query: GET_CARTS,
    })

    if (data.removecart) {
      const latest = old.carts.filter(
        (item) => item.PriceID != data.removecart.PriceID
      )
      cache.writeQuery({
        query: GET_CARTS,
        data: { carts: [...latest] },
      })
    }
  }

  const [removeCart] = useMutation(REMOVE_CART, { update: deleteCache })

  const [selectedPrice, setSelectedPrice] = useState()

  const classes = useStyles(shopconfig)
  const history = useHistory()

  const [dbhost, setDbhost] = useState(
    window.location.host.endsWith('.frapp.cloud')
      ? window.location.host.split('.frapp.cloud')[0]
      : ''
  )

  useEffect(() => {
    if (product.ProductPrices)
      setSelectedPrice(product.ProductPrices.split(',')[0].split('|')[2])
  }, [product])

  /*const [dbhost, setDbhost] = useState('shop')*/

  useEffect(() => {
    const arr = []
    productimages.map((a) => {
      arr.push({
        original: `${restApi}/media/${dbhost}/uploads/invoice/${a.FileName}`,
        thumbnail: `${restApi}/media/${dbhost}/uploads/invoice/${a.FileName}`,
      })
    })

    setImages(arr)
  }, [productimages])
  /* 
  const images = [
    {
      original: `${restApi}/media/${dbhost}/uploads/invoice/${product.ProductImage}`,
      thumbnail: `${restApi}/media/${dbhost}/uploads/invoice/${product.ProductImage}`,
    },
  ] */

  /* useEffect(() => {
    if (product.ProductPrices.split(',').length > 1) {
      setSelectedPrice(product.ProductPrices.split(',')[0].split('|')[2])
      console.log(product.ProductPrices.split(',')[0].split('|')[2])
    }
  }, []) */

  /* 
  const flickityOptions = {
    initialIndex: 0,
    autoPlay: 3000,
    pauseAutoPlayOnHover: false,
    wrapAround: false,
    fullscreen: true,
    adaptiveHeight: false,
    prevNextButtons: true,
    pageDots: true,
    imagesLoaded: true,
    lazyLoad: true,
    setGallerySize: false,
  } */

  useEffect(() => {
    const totalAmount = carts.reduce((a, b) => a + b.InvoiceAmount, 0)
    setTotalAmount(totalAmount)
  }, [carts])

  if (!product.id || productimages.length === 0)
    return <React.Fragment></React.Fragment>

  return (
    <React.Fragment>
      <Hidden only={['sm', 'xs']}>
        <div style={{ backgroundColor: 'white' }}>
          <Container maxWidth="md">
            {categories.length > 0 && (
              <MenuCategory
                shopconfig={shopconfig}
                logo={`${restApi}/media/${dbhost}/uploads/logo/${shopconfig.COMLOGO}`}
                categories={categories}
              />
            )}

            {categories.length == 0 && (
              <Menu
                shopconfig={shopconfig}
                logo={`${restApi}/media/${dbhost}/uploads/logo/${shopconfig.COMLOGO}`}
                value={0}
              />
            )}
          </Container>
        </div>
      </Hidden>
      <Container classes={{ root: classes.containerroot }} maxWidth="md">
        <div className={classes.toolbar}>
          <div className={classes.root}>
            <div style={{ display: 'flex' }}>
              <Paper classes={{ root: classes.paperroot }} variant="outlined">
                <div>
                  <Button
                    variant="outlined"
                    size="large"
                    color="primary"
                    style={{ minWidth: 0, marginBottom: 20 }}
                    onClick={() => {
                      history.goBack()
                      // window.history.back()
                    }}
                  >
                    <ArrowBackIosIcon /> Back
                  </Button>
                </div>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} md={5}>
                    {/* <Flickity
              className={'carousel'} // default ''
              elementType={'div'} // default 'div'
              options={flickityOptions} // takes flickity options {}
              disableImagesLoaded={false} // default false
              reloadOnUpdate={true} // default false
              static={false} // default false
            >
              <img
                width="100%"
                src={`${restApi}/${dbhost}/uploads/media/invoice/${product.ProductImage}`}
                alt=""
              />
              <img
                width="100%"
                src={`${restApi}/${dbhost}/uploads/media/invoice/${product.ProductImage}`}
                alt=""
              />
            </Flickity> */}
                    <ImageGallery
                      showPlayButton={false}
                      showFullscreenButton={true}
                      useBrowserFullscreen={true}
                      items={images}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={7}>
                    <h2 style={{ marginBottom: 0 }}>{product.ProductName}</h2>

                    {/*  {product.Category && (
              <Typography variant="body2" color="textSecondary" component="p">
                {`${product.Category}`}
              </Typography>
            )} */}

                    <div
                      className={classes.productDesc}
                      dangerouslySetInnerHTML={{
                        __html: product.ProductDesc,
                      }}
                    />

                    <div style={{ paddingTop: 25, minHeight: 30 }}>
                      {product.ProductPrices &&
                        product.ProductPrices.split(',').length === 1 &&
                        product.ProductPrices.split(',').map((price, i) => {
                          const priceitem = price.split('|')
                          return (
                            <div key={i}>
                              <span
                                style={{
                                  fontWeight: 'bold',
                                  fontSize: 17,
                                  display: 'inline-block',
                                  paddingRight: 10,
                                }}
                              >
                                {priceitem[0]} - {priceitem[3]}
                              </span>
                            </div>
                          )
                        })}

                      {selectedPrice &&
                        product.ProductPrices &&
                        product.ProductPrices.split(',').length > 1 && (
                          <React.Fragment>
                            <Select
                              defaultValue={selectedPrice}
                              value={selectedPrice}
                              onChange={(e) => {
                                setSelectedPrice(e.target.value)
                              }}
                              input={<BootstrapInput />}
                            >
                              {product.ProductPrices.split(',').map(
                                (price, i) => {
                                  const priceitem = price.split('|')
                                  return (
                                    <MenuItem key={i} value={priceitem[2]}>
                                      {priceitem[3]} - {priceitem[0]}
                                    </MenuItem>
                                  )
                                }
                              )}
                            </Select>
                          </React.Fragment>
                        )}
                    </div>

                    {product.UnitsInStock > 10 && (
                      <div
                        style={{ marginTop: 15, color: '#757575' }}
                      >{`${product.UnitsInStock} piece available`}</div>
                    )}

                    {product.UnitsInStock < 11 && product.UnitsInStock > 0 && (
                      <div
                        style={{ marginTop: 15, color: 'red' }}
                      >{`${product.UnitsInStock} left in stock`}</div>
                    )}

                    {product.UnitsInStock == 0 && (
                      <div
                        style={{ marginTop: 15, color: 'red' }}
                      >{`No stock`}</div>
                    )}

                    <div style={{ marginTop: 15 }}>
                      {!carts.find(
                        (a) =>
                          a.ProductID == product.id &&
                          a.PriceID == selectedPrice
                      ) && (
                        <Button
                          variant="outlined"
                          size="large"
                          color="primary"
                          onClick={() => {
                            if (!Cookies.get('signedin')) {
                              history.push('/login')
                              return
                            }
                            insertCart({
                              variables: {
                                ProductID: product.id,
                                PriceID: selectedPrice,
                                Qty: 1,
                              },
                            })
                          }}
                        >
                          Add
                        </Button>
                      )}
                      {carts.find(
                        (a) =>
                          a.ProductID == product.id &&
                          a.PriceID == selectedPrice
                      ) && (
                        <React.Fragment>
                          <Button
                            variant="outlined"
                            size="large"
                            color="primary"
                            style={{ minWidth: 0, marginRight: 10 }}
                            onClick={() => {
                              if (!Cookies.get('signedin')) {
                                history.push('/login')
                                return
                              }
                              updateCart({
                                variables: {
                                  ProductID: product.id,
                                  PriceID: selectedPrice,
                                  Qty:
                                    carts.find(
                                      (a) =>
                                        a.ProductID == product.id &&
                                        a.PriceID == selectedPrice
                                    ).Qty + 1,
                                },
                              })
                            }}
                          >
                            <AddIcon />
                          </Button>

                          <Button
                            variant="outlined"
                            size="large"
                            color="primary"
                            style={{ minWidth: 0 }}
                            onClick={() => {
                              if (!Cookies.get('signedin')) {
                                history.push('/login')
                                return
                              }
                              if (
                                carts.find(
                                  (a) =>
                                    a.ProductID == product.id &&
                                    a.PriceID == selectedPrice
                                ).Qty > 1
                              ) {
                                updateCart({
                                  variables: {
                                    ProductID: product.id,
                                    PriceID: selectedPrice,
                                    Qty:
                                      carts.find(
                                        (a) =>
                                          a.ProductID == product.id &&
                                          a.PriceID == selectedPrice
                                      ).Qty - 1,
                                  },
                                })
                              } else {
                                setTimeout(() => {
                                  removeCart({
                                    variables: {
                                      id: product.id,
                                      PriceID: selectedPrice,
                                    },
                                  })
                                }, 1)
                              }
                            }}
                          >
                            <RemoveIcon />
                          </Button>
                        </React.Fragment>
                      )}
                    </div>
                    <div
                      style={{
                        minHeight: carts.find((a) => a.ProductID == product.id)
                          ? 38
                          : 40,
                        textAlign: 'left',
                      }}
                    >
                      {carts.find(
                        (a) =>
                          a.ProductID == product.id &&
                          a.PriceID == selectedPrice
                      ) && (
                        <h3
                          style={{
                            margin: 0,
                            paddingTop: 10,
                            color: shopconfig.COLORPRIMARY,
                          }}
                        >
                          {
                            carts.find(
                              (a) =>
                                a.ProductID == product.id &&
                                a.PriceID == selectedPrice
                            ).Qty
                          }{' '}
                          in trolley
                        </h3>
                      )}
                    </div>
                  </Grid>
                </Grid>
              </Paper>
              <Hidden only={['sm', 'xs']}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexShrink: 0,
                    width: 240,
                    borderLeft: '1px solid #ccc',
                    borderRight: '1px solid #ccc',
                    transform: 'translateZ(0)',
                    position: '-webkit-sticky',
                    position: 'sticky',
                    height: '100vh',
                    margin: 0,
                    padding: 0,
                    top: 0,
                  }}
                >
                  <div
                    style={{
                      backgroundColor: '#ffffff',
                      borderBottom: '1px solid #cccccc',
                      padding: 10,
                    }}
                  >
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        style={{
                          fontSize: 15,
                        }}
                      >
                        Total:{' '}
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        style={{
                          textAlign: 'right',
                          fontWeight: 'bold',
                          fontSize: 15,
                        }}
                      >
                        RM {parseFloat(totalAmount).toFixed(2)}{' '}
                      </Grid>
                    </Grid>
                    <Divider classes={{ root: classes.dividerroot }} />
                    <Button
                      variant="contained"
                      disableElevation
                      fullWidth
                      size="medium"
                      color="primary"
                      component={Link2}
                      to="/checkout"
                      /* onClick={() => {
                        scrollToTop()
                      }} */
                    >
                      Checkout
                    </Button>
                  </div>
                  <List className={classes.listRoot}>
                    <Cart data={carts} removeCart={removeCart} />
                  </List>
                </div>
              </Hidden>
            </div>
          </div>
        </div>
      </Container>
    </React.Fragment>
  )
}
