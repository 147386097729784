import React, { useState, useRef } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import {
  Grid,
  Card,
  CardContent,
  Typography,
  CardActionArea,
  CardActions,
  Button,
  CardMedia,
  List,
  Link,
  Paper,
} from '@material-ui/core/'

import { green, purple } from '@material-ui/core/colors'
import Divider from '@material-ui/core/Divider'
import Container from '@material-ui/core/Container'
import { Link as Link2 } from 'react-router-dom'
import Hidden from '@material-ui/core/Hidden'
import Pagination from '@material-ui/lab/Pagination'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'

import { GET_PRODUCTS, TOTAL_PRODUCTS } from '../graphql/product'
import {
  GET_CARTS,
  INSERT_CART,
  UPDATE_CART,
  REMOVE_CART,
} from '../graphql/cart'
import { GET_MYORDERS } from '../graphql/order'
import { GET_CATEGORIES } from '../graphql/category'
import { GET_SHOPCONFIG } from '../graphql/config'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { useEffect } from 'react'
import Cart from './cart'
import ShopDetails from './shopDetails'
import Loading from '../common/loading'

import Menu from './menu'
import MenuCategory from './menuCategory'

const restApi = 'http://frapp.cloud:3501'

const scrollToTop = () => {
  window.scrollTo(0, 0)
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(0),
  },
  listRoot: {
    width: '100%',
    maxWidth: '36ch',
    padding: 0,
    overflowY: 'scroll',
    flexGrow: 1,
    backgroundColor: '#ebebeb',
  },
  media: {
    height: 150,
    margin: theme.spacing(1),
  },
  card: {},
  paperroot: {
    padding: theme.spacing(1),
    border: '1px solid #b4b4b4',
    marginBottom: theme.spacing(1),
  },
  dividerroot: {
    marginBottom: theme.spacing(2),
  },
  containerroot: {
    paddingTop: 0,
    paddingBottom: 50,
    paddingLeft: 0,
    paddingRight: 0,
  },
}))

export default function Shop(props) {
  const classes = useStyles()
  const [selectedProductID, setSelectedProductID] = useState()

  const [dbhost, setDbhost] = useState(
    window.location.host.endsWith('.frapp.cloud')
      ? window.location.host.split('.frapp.cloud')[0]
      : ''
  )

  /*const [dbhost, setDbhost] = useState('shop')*/

  const {
    loading: loadingConfig,
    data: { shopconfig } = { shopconfig: {} },
    refetch: refetchConfig,
  } = useQuery(GET_SHOPCONFIG)

  const {
    loading: loadingTotal,
    data: { myorders } = { myorders: [] },
    refetch: refetchOrder,
  } = useQuery(GET_MYORDERS)

  const {
    loading: loadingCarts,
    data: { carts } = { carts: [] },
    refetch: refetchCart,
  } = useQuery(GET_CARTS)

  const addCache = (cache, { data }) => {
    const old = cache.readQuery({
      query: GET_CARTS,
    })
    const latest = data.insertcart
    cache.writeQuery({
      query: GET_CARTS,
      data: { carts: [latest, ...old.carts] },
    })
  }

  const [insertCart] = useMutation(INSERT_CART, {
    update: addCache,
  })

  const updateCache = (cache, { data }) => {
    const old = cache.readQuery({
      query: GET_CARTS,
    })
    const latest = data.updatecart
    const foundIndex = old.carts.findIndex((item) => item.id === latest.id)
    old.carts.splice(foundIndex, 1, latest)
    cache.writeQuery({
      query: GET_CARTS,
      data: { carts: [...old.carts] },
    })
  }

  const [updateCart] = useMutation(UPDATE_CART, { update: updateCache })

  const deleteCache = (cache, { data }) => {
    const old = cache.readQuery({
      query: GET_CARTS,
    })
    if (data.removecart) {
      const latest = old.carts.filter(
        (item) => item.ProductID != selectedProductID
      )
      cache.writeQuery({
        query: GET_CARTS,
        data: { carts: [...latest] },
      })
    }
    setSelectedProductID()
  }

  const [removeCart] = useMutation(REMOVE_CART, { update: deleteCache })

  const { data: { categories } = { categories: [] } } = useQuery(GET_CATEGORIES)

  useEffect(() => {
    refetchCart()
    refetchOrder()
  }, [props])

  return (
    <React.Fragment>
      <Hidden only={['sm', 'xs']}>
        <div style={{ backgroundColor: 'white' }}>
          <Container maxWidth="md">
            {categories.length > 0 && (
              <MenuCategory
                shopconfig={shopconfig}
                logo={`${restApi}/media/${dbhost}/uploads/logo/${shopconfig.COMLOGO}`}
                categories={categories}
              />
            )}

            {categories.length == 0 && (
              <Menu
                shopconfig={shopconfig}
                logo={`${restApi}/media/${dbhost}/uploads/logo/${shopconfig.COMLOGO}`}
                value={0}
              />
            )}
          </Container>
        </div>
      </Hidden>
      <Container classes={{ root: classes.containerroot }} maxWidth="md">
        <div className={classes.toolbar}>
          <div className={classes.root}>
            <div style={{ display: 'flex' }}>
              <div style={{ flexGrow: 1, padding: 10 }}>
                {/* <h2>My Orders</h2> */}

                <div style={{ fontSize: 17, fontWeight: 'bold' }}>
                  Open Order
                </div>

                <Divider classes={{ root: classes.dividerroot }} />
                {myorders.map(
                  (order) =>
                    order.StatusCode == 'ORDERCREATED' && (
                      <Paper
                        classes={{ root: classes.paperroot }}
                        variant="outlined"
                      >
                        <Grid
                          container
                          spacing={2}
                          direction="row"
                          justify="flex-start"
                          alignItems="flex-start"
                        >
                          <Grid item xs={12} md={2}>
                            <strong style={{ fontSize: 18, color: 'black' }}>
                              {new Intl.DateTimeFormat('en-GB', {
                                month: 'short',
                                day: 'numeric',
                              }).format(new Date(order.CreatedOn))}
                            </strong>
                          </Grid>

                          <Grid item xs={12} md={5}>
                            <strong>Order no: </strong>
                            {order.OrderNo}
                            <br />
                            <br />
                            <Button
                              variant="contained"
                              disableElevation
                              color="primary"
                              component={Link2}
                              to={`/order/detail/${order.OrderNo}`}
                            >
                              Make payment
                            </Button>
                          </Grid>

                          <Hidden only={['sm', 'xs']}>
                            <Grid item xs={12} md={5}>
                              <strong>Items: </strong>
                              {order.TotalItem}
                              <br />
                              <strong>Total Amount: </strong>
                              RM{parseFloat(order.TotalAmount).toFixed(2)}
                              <br />
                              <strong>Delivery Charges: </strong>
                              RM{parseFloat(order.DeliveryCharges).toFixed(2)}
                            </Grid>
                          </Hidden>
                        </Grid>
                      </Paper>
                    )
                )}

                <br />

                <div style={{ fontSize: 17, fontWeight: 'bold' }}>
                  Pending Delivery
                </div>

                <Divider classes={{ root: classes.dividerroot }} />
                {myorders.map(
                  (order) =>
                    order.StatusCode != 'ORDERCREATED' &&
                    order.StatusCode != 'COMPLETED' &&
                    order.StatusCode != 'CLOSED' && (
                      <Paper
                        classes={{ root: classes.paperroot }}
                        variant="outlined"
                      >
                        <Grid
                          container
                          spacing={2}
                          direction="row"
                          justify="flex-start"
                          alignItems="flex-start"
                        >
                          <Grid item xs={12} md={2}>
                            <strong style={{ fontSize: 18, color: 'black' }}>
                              {new Intl.DateTimeFormat('en-GB', {
                                month: 'short',
                                day: 'numeric',
                              }).format(new Date(order.CreatedOn))}
                            </strong>
                            <h3
                              style={{
                                marginTop: 0,
                                marginBottom: 0,
                                color:
                                  order.StatusCode == 'ORDERPAID'
                                    ? 'red'
                                    : 'green',
                              }}
                            >
                              {order.StatusCode == 'ORDERPAID'
                                ? 'Verifying Payment'
                                : order.Status}
                            </h3>
                          </Grid>

                          <Grid item xs={12} md={5}>
                            <strong>Order no: </strong>
                            {order.OrderNo}
                            <br />
                            <br />
                            <Button
                              variant="outlined"
                              disableElevation
                              color="primary"
                              component={Link2}
                              to={`/order/detail/${order.OrderNo}`}
                            >
                              Order details
                            </Button>
                          </Grid>

                          <Hidden only={['sm', 'xs']}>
                            <Grid item xs={12} md={5}>
                              <strong>Items: </strong>
                              {order.TotalItem}
                              <br />
                              <strong>Total Amount: </strong>
                              RM{parseFloat(order.TotalAmount).toFixed(2)}
                              <br />
                              <strong>Delivery Charges: </strong>
                              RM{parseFloat(order.DeliveryCharges).toFixed(2)}
                              <br />
                            </Grid>
                          </Hidden>
                        </Grid>
                      </Paper>
                    )
                )}

                <br />

                <div style={{ fontSize: 17, fontWeight: 'bold' }}>
                  Previous Orders
                </div>

                <Divider classes={{ root: classes.dividerroot }} />
                {myorders.map(
                  (order) =>
                    order.StatusCode == 'COMPLETED' && (
                      <Paper
                        classes={{ root: classes.paperroot }}
                        variant="outlined"
                      >
                        <Grid
                          container
                          spacing={2}
                          direction="row"
                          justify="flex-start"
                          alignItems="flex-start"
                        >
                          <Grid item xs={12} md={2}>
                            <strong style={{ fontSize: 18, color: 'black' }}>
                              {new Intl.DateTimeFormat('en-GB', {
                                month: 'short',
                                day: 'numeric',
                              }).format(new Date(order.CreatedOn))}
                            </strong>
                          </Grid>

                          <Grid item xs={12} md={5}>
                            <strong>Order no: </strong>
                            {order.OrderNo}
                            <br />
                            <br />
                            <Button
                              variant="outlined"
                              disableElevation
                              color="primary"
                              component={Link2}
                              to={`/order/detail/${order.OrderNo}`}
                            >
                              Order details
                            </Button>
                          </Grid>

                          <Hidden only={['sm', 'xs']}>
                            <Grid item xs={12} md={5}>
                              <strong>Items: </strong>
                              {order.TotalItem}
                              <br />
                              <strong>Total Amount: </strong>
                              RM{parseFloat(order.TotalAmount).toFixed(2)}
                              <br />
                              <strong>Delivery Charges: </strong>
                              RM{parseFloat(order.DeliveryCharges).toFixed(2)}
                              <br />
                            </Grid>
                          </Hidden>
                        </Grid>
                      </Paper>
                    )
                )}
              </div>

              <Hidden only={['sm', 'xs']}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexShrink: 0,
                    width: 240,
                    borderLeft: '1px solid #ccc',
                    borderRight: '1px solid #ccc',
                    transform: 'translateZ(0)',
                    position: '-webkit-sticky',
                    position: 'sticky',
                    height: '100vh',
                    margin: 0,
                    padding: 0,
                    top: 0,
                  }}
                >
                  <div
                    style={{
                      backgroundColor: '#ffffff',
                      borderBottom: '1px solid #cccccc',
                      padding: 10,
                    }}
                  >
                    <Button
                      variant="contained"
                      disableElevation
                      fullWidth
                      size="medium"
                      color="primary"
                      component={Link2}
                      to="/checkout"
                      onClick={() => {
                        scrollToTop()
                      }}
                    >
                      Checkout
                    </Button>
                  </div>
                  <List className={classes.listRoot}>
                    <Cart data={carts} />
                  </List>
                </div>
              </Hidden>
            </div>
          </div>
        </div>
      </Container>
    </React.Fragment>
  )
}
