import React from 'react'
import Paper from '@material-ui/core/Paper'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { useHistory, Link as Link2 } from 'react-router-dom'

export default function Menu(props) {
  const [value, setValue] = React.useState(2)

  const history = useHistory()
  const url = ['/', '/terms', '/faq']

  const handleChange = (event, newValue) => {
    //setValue(newValue)

    history.push(url[newValue])
  }

  return (
    <div style={{ minHeight: 100 }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
          paddingTop: 10,
        }}
      >
        {props.shopconfig && props.shopconfig.COMLOGO && (
          <Link2 to="/">
            <img
              style={{ height: 60, verticalAlign: 'middle' }}
              src={props.logo}
            />
          </Link2>
        )}
      </div>
      <Tabs
        value={props.value}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChange}
        aria-label="disabled tabs example"
      >
        <Tab disableRipple label="Shop" />
        <Tab disableRipple label="Terms & Conditions" />
        <Tab disableRipple label="FAQ" />
      </Tabs>
    </div>
  )
}
