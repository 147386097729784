import React, { useEffect } from 'react'
import { useHistory, Link as Link2 } from 'react-router-dom'

import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}))

export default function MenuCategory(props) {
  const [value, setValue] = React.useState()

  const history = useHistory()

  useEffect(() => {
    const selected = props.categories.find((a) => a.id == props.value)
    setValue(props.categories.indexOf(selected))
  }, [props])

  const handleChange = (event, newValue) => {
    //setValue(newValue)

    /* const selected = props.categories.find(a=> a.id == newValue)
    setValue(props.categories.findIndex(selected)) */

    history.push(
      `/list/${props.categories[newValue].id}/${props.categories[newValue].Prettier}`
    )
  }

  return (
    <div style={{ minHeight: props.logo ? 100 : 0 }}>
      {props.logo && (
        <div
          style={{
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            paddingTop: 10,
          }}
        >
          {props.shopconfig && props.shopconfig.COMLOGO && (
            <Link2 to="/">
              <img
                style={{ height: 60, verticalAlign: 'middle' }}
                src={props.logo}
              />
            </Link2>
          )}
        </div>
      )}
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example"
      >
        {props.categories &&
          props.categories.map((a, i) => (
            <Tab
              disableRipple
              label={a.CategoryName}
              key={i}
              {...a11yProps(i)}
            />
          ))}
      </Tabs>
    </div>
  )
}
