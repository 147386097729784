import { gql } from 'apollo-boost'

export const GET_SHOPCONFIG = gql`
  {
    shopconfig {
      COMNAM
      COMADD
      COMTEL
      COMFAX
      COMEML
      COMURL
      COMDIR
      COMREG
      INVPFX
      ODRPFX
      GSTID
      BTBANKNAME
      BTACCNO
      BTACCNAME
      COMLOG
      COMSHO
      COMADD1
      COMADD2
      COMADD3
      WHATSAPP
      COLORPRIMARY
      COLORSECONDARY
      COMLOGO
      TNC
      FAQ
      TOUCHNGO
    }
  }
`

export const UPDATE_CONFIG = gql`
  mutation UpdateConfig($formKey: String, $formValue: String) {
    updateconfig(formKey: $formKey, formValue: $formValue)
  }
`
