import React, { useState, useEffect } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { Link as Link2, useHistory } from 'react-router-dom'
import Link from '@material-ui/core/Link'
import Paper from '@material-ui/core/Paper'
import ListItem from '@material-ui/core/ListItem'
import Divider from '@material-ui/core/Divider'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import IconButton from '@material-ui/core/IconButton'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputBase from '@material-ui/core/InputBase'
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined'
import IndeterminateCheckBoxOutlinedIcon from '@material-ui/icons/IndeterminateCheckBoxOutlined'
import CartItem from './cartItem'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import { GET_SHOPCONFIG } from '../graphql/config'
import { useMutation, useQuery } from '@apollo/react-hooks'

import ImageGallery from 'react-image-gallery'

import 'react-image-gallery/styles/css/image-gallery.css'
import Flickity from 'react-flickity-component'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import HomeIcon from '@material-ui/icons/Home'
import WhatshotIcon from '@material-ui/icons/Whatshot'
import GrainIcon from '@material-ui/icons/Grain'
import Cookies from 'js-cookie'
import { PRODUCT_IMAGES } from '../graphql/product'

const restApi = 'http://frapp.cloud:3501'
const useStyles = makeStyles((theme) => ({
  paperroot: {
    padding: theme.spacing(2),
    border: '1px solid #b4b4b4',
    marginBottom: theme.spacing(1),
  },
  link: {
    display: 'flex',
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
  productDesc: {
    fontWeight: 'normal',
  },
}))

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 0,
    position: 'relative',
    backgroundColor: theme.palette.grey[200],
    border: 1,
    borderColor: theme.palette.primary.main,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
  },
}))(InputBase)

export default function ShopDetails(props) {
  const [images, setImages] = useState([])

  const {
    loading: loadingConfig,
    data: { shopconfig } = { shopconfig: {} },
    refetch: refetchConfig,
  } = useQuery(GET_SHOPCONFIG)

  const {
    loading: loadingImages,
    data: { productimages } = { productimages: [] },
    refetch: refetchImages,
  } = useQuery(PRODUCT_IMAGES, { variables: { id: props.data.id } })

  const [selectedPrice, setSelectedPrice] = useState(
    props.data.ProductPrices.split(',')[0].split('|')[2]
  )

  const classes = useStyles(shopconfig)
  const history = useHistory()

  const [dbhost, setDbhost] = useState(
    window.location.host.endsWith('.frapp.cloud')
      ? window.location.host.split('.frapp.cloud')[0]
      : ''
  )
  /*const [dbhost, setDbhost] = useState('shop')*/
  useEffect(() => {
    const arr = []
    productimages.map((a) => {
      arr.push({
        original: `${restApi}/media/${dbhost}/uploads/invoice/${a.FileName}`,
        thumbnail: `${restApi}/media/${dbhost}/uploads/invoice/${a.FileName}`,
      })
    })

    console.log(arr)

    setImages(arr)
  }, [productimages])
  /* 
  const images = [
    {
      original: `${restApi}/media/${dbhost}/uploads/invoice/${props.data.ProductImage}`,
      thumbnail: `${restApi}/media/${dbhost}/uploads/invoice/${props.data.ProductImage}`,
    },
  ] */

  /* useEffect(() => {
    if (props.data.ProductPrices.split(',').length > 1) {
      setSelectedPrice(props.data.ProductPrices.split(',')[0].split('|')[2])
      console.log(props.data.ProductPrices.split(',')[0].split('|')[2])
    }
  }, []) */

  /* 
  const flickityOptions = {
    initialIndex: 0,
    autoPlay: 3000,
    pauseAutoPlayOnHover: false,
    wrapAround: false,
    fullscreen: true,
    adaptiveHeight: false,
    prevNextButtons: true,
    pageDots: true,
    imagesLoaded: true,
    lazyLoad: true,
    setGallerySize: false,
  } */

  if (!props.data.id) return <div>no item</div>

  return (
    <React.Fragment>
      {/* <Breadcrumbs aria-label="breadcrumb">
        <Typography color="textPrimary">
          <HomeIcon className={classes.icon} />
        </Typography>
        <Typography color="textPrimary">{props.data.Category}</Typography>
      </Breadcrumbs> */}
      <Paper classes={{ root: classes.paperroot }} variant="outlined">
        <div>
          <Button
            variant="outlined"
            size="large"
            color="primary"
            style={{ minWidth: 0, marginBottom: 20 }}
            onClick={() => {
              props.setBackProductID(props.data.id)
              props.setBack(new Date() + Math.random())
              props.setProduct(null)
            }}
          >
            <ArrowBackIosIcon /> Back
          </Button>
        </div>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={5}>
            {/* <Flickity
              className={'carousel'} // default ''
              elementType={'div'} // default 'div'
              options={flickityOptions} // takes flickity options {}
              disableImagesLoaded={false} // default false
              reloadOnUpdate={true} // default false
              static={false} // default false
            >
              <img
                width="100%"
                src={`${restApi}/${dbhost}/uploads/media/invoice/${props.data.ProductImage}`}
                alt=""
              />
              <img
                width="100%"
                src={`${restApi}/${dbhost}/uploads/media/invoice/${props.data.ProductImage}`}
                alt=""
              />
            </Flickity> */}
            <ImageGallery
              showPlayButton={false}
              showFullscreenButton={true}
              useBrowserFullscreen={true}
              items={images}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={7}>
            <h2 style={{ marginBottom: 0 }}>{props.data.ProductName}</h2>

            {/*  {props.data.Category && (
              <Typography variant="body2" color="textSecondary" component="p">
                {`${props.data.Category}`}
              </Typography>
            )} */}

            <div
              className={classes.productDesc}
              dangerouslySetInnerHTML={{ __html: props.data.ProductDesc }}
            />

            <div style={{ paddingTop: 25, minHeight: 30 }}>
              {props.data.ProductPrices &&
                props.data.ProductPrices.split(',').length === 1 &&
                props.data.ProductPrices.split(',').map((price, i) => {
                  const priceitem = price.split('|')
                  return (
                    <div key={i}>
                      <span
                        style={{
                          fontWeight: 'bold',
                          fontSize: 17,
                          display: 'inline-block',
                          paddingRight: 10,
                        }}
                      >
                        {priceitem[0]} - {priceitem[3]}
                      </span>
                    </div>
                  )
                })}

              {props.data.ProductPrices &&
                props.data.ProductPrices.split(',').length > 1 && (
                  <React.Fragment>
                    <Select
                      defaultValue={selectedPrice}
                      value={selectedPrice}
                      onChange={(e) => {
                        setSelectedPrice(e.target.value)
                      }}
                      input={<BootstrapInput />}
                    >
                      {props.data.ProductPrices.split(',').map((price, i) => {
                        const priceitem = price.split('|')
                        return (
                          <MenuItem key={i} value={priceitem[2]}>
                            {priceitem[3]} - {priceitem[0]}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </React.Fragment>
                )}
            </div>

            {props.data.UnitsInStock > 10 && (
              <div
                style={{ marginTop: 0, color: '#757575' }}
              >{`${props.data.UnitsInStock} piece available`}</div>
            )}

            {props.data.UnitsInStock < 11 && props.data.UnitsInStock > 0 && (
              <div
                style={{ marginTop: 0, color: 'red' }}
              >{`${props.data.UnitsInStock} left in stock`}</div>
            )}

            {props.data.UnitsInStock == 0 && (
              <div style={{ marginTop: 0, color: 'red' }}>{`No stock`}</div>
            )}

            <div style={{ marginTop: 15 }}>
              {!props.carts.find(
                (a) =>
                  a.ProductID == props.data.id && a.PriceID == selectedPrice
              ) && (
                <Button
                  variant="outlined"
                  size="large"
                  color="primary"
                  onClick={() => {
                    if (!Cookies.get('signedin')) {
                      history.push('/login')
                      return
                    }
                    props.setSelectedProductID(props.data.id)
                    props.insertCart({
                      variables: {
                        ProductID: props.data.id,
                        PriceID: selectedPrice,
                        Qty: 1,
                      },
                    })
                  }}
                >
                  Add
                </Button>
              )}
              {props.carts.find(
                (a) =>
                  a.ProductID == props.data.id && a.PriceID == selectedPrice
              ) && (
                <React.Fragment>
                  <Button
                    variant="outlined"
                    size="large"
                    color="primary"
                    style={{ minWidth: 0, marginRight: 10 }}
                    onClick={() => {
                      if (!Cookies.get('signedin')) {
                        history.push('/login')
                        return
                      }
                      props.updateCart({
                        variables: {
                          ProductID: props.data.id,
                          PriceID: selectedPrice,
                          Qty:
                            props.carts.find(
                              (a) =>
                                a.ProductID == props.data.id &&
                                a.PriceID == selectedPrice
                            ).Qty + 1,
                        },
                      })
                    }}
                  >
                    <AddIcon />
                  </Button>

                  <Button
                    variant="outlined"
                    size="large"
                    color="primary"
                    style={{ minWidth: 0 }}
                    onClick={() => {
                      if (!Cookies.get('signedin')) {
                        history.push('/login')
                        return
                      }
                      if (
                        props.carts.find(
                          (a) =>
                            a.ProductID == props.data.id &&
                            a.PriceID == selectedPrice
                        ).Qty > 1
                      ) {
                        props.updateCart({
                          variables: {
                            ProductID: props.data.id,
                            PriceID: selectedPrice,
                            Qty:
                              props.carts.find(
                                (a) =>
                                  a.ProductID == props.data.id &&
                                  a.PriceID == selectedPrice
                              ).Qty - 1,
                          },
                        })
                      } else {
                        props.setSelectedProductID(props.data.id)
                        setTimeout(() => {
                          props.removeCart({
                            variables: {
                              id: props.data.id,
                              PriceID: selectedPrice,
                            },
                          })
                        }, 1)
                      }
                    }}
                  >
                    <RemoveIcon />
                  </Button>
                </React.Fragment>
              )}
            </div>
            <div
              style={{
                minHeight: props.carts.find((a) => a.ProductID == props.data.id)
                  ? 38
                  : 40,
                textAlign: 'left',
              }}
            >
              {props.carts.find(
                (a) =>
                  a.ProductID == props.data.id && a.PriceID == selectedPrice
              ) && (
                <h3
                  style={{
                    margin: 0,
                    paddingTop: 10,
                    color: shopconfig.COLORPRIMARY,
                  }}
                >
                  {
                    props.carts.find(
                      (a) =>
                        a.ProductID == props.data.id &&
                        a.PriceID == selectedPrice
                    ).Qty
                  }{' '}
                  in trolley
                </h3>
              )}
            </div>
          </Grid>
        </Grid>
      </Paper>
    </React.Fragment>
  )
}
